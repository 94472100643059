<template>
  <div class="dept-manage-page">
    <x-table
      :no-data-text="CA('deptManage_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      rowKey="unitId"
      @add="add"
      @search="search"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
      :width="450"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="组织名称" prop="unitName">
          <Input v-model="form.unitName" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="组织电话" prop="tel">
          <Input v-model="form.tel" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="联系人" prop="contact">
          <Input v-model="form.contact" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="备注" prop="remark">
          <Input
            type="textarea"
            :rows="3"
            v-model="form.remark"
            style="width: 300px"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import CU from "@/common/util";
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "名称",
            minWidth: 300,
            tree: true,
            key: "unitName",
          },
          {
            title: "负责人",
            width: 100,
            key: "contact",
          },
          {
            title: "电话",
            width: 148,
            key: "tel",
          },
          {
            title: "状态",
            width: 100,
            render: (h, { row }) => {
              let state_str =
                +row.state === 0
                  ? "待启用"
                  : +row.state === 1
                  ? "正常"
                  : "已停用";
              return <span>{state_str}</span>;
            },
          },
          {
            title: "备注",
            width: 300,
            ellipsis: true,
            tooltip: true,
            key: "remark",
          },
          {
            title: "操作",
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("deptManage_add") && (
                    <a style="margin-right: 10px" onClick={() => this.add(row)}>
                      新增下级组织
                    </a>
                  )}
                  {+row.state !== 1 && this.CA("deptManage_start") && (
                    <Poptip
                      transfer={true}
                      confirm
                      title="确认启用该组织吗？"
                      on-on-ok={() => this.start(row)}
                    >
                      <a style="margin-right: 10px">启用</a>
                    </Poptip>
                  )}
                  {+row.state === 1 && this.CA("deptManage_stop") && (
                    <Poptip
                      transfer={true}
                      confirm
                      title="确认停用该组织吗？"
                      on-on-ok={() => this.stop(row)}
                    >
                      <a style="margin-right: 10px">停用</a>
                    </Poptip>
                  )}
                  {this.CA("deptManage_edit") && (
                    <a
                      on-click={() => this.edit(row)}
                      style="margin-right: 10px"
                    >
                      编辑
                    </a>
                  )}
                  {+row.state === 0 && this.CA("deptManage_delete") && (
                    <Poptip
                      transfer
                      confirm
                      title="确认删除该组织吗？"
                      on-on-ok={() => this.delete(row)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: "新增组织",
            ca: "deptManage_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "组织名称",
              component: "input",
              field: "unitName",
              defaultValue: "",
            },
          ],
        },
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },

      form: {
        unitId: "",
        unitName: "",
        tel: "",
        pid: "",
        pname: "",
        contact: "",
        remark: "",
      },
      rules: {
        unitName: [
          {
            required: true,
            message: "请输入组织名称",
          },
        ],
        tel: [
          {
            required: true,
            message: "请输入组织号码",
          },
          {
            validator: (rule, value, callback) => {
              if (CU.validatePhone(value) || CU.validateLandline(value)) {
                callback();
                return;
              }
              callback("联系电话格式不正确");
            },
          },
        ],
        contact: [
          {
            required: true,
            message: "请填写组织负责人",
          },
        ],
      },
      search_data: {},
    };
  },
  methods: {
    search(data) {
      this.search_data = data;
      this.getList();
    },

    //   新增组织
    add(parent) {
      parent && (this.form.pid = parent.unitId);
      parent && (this.form.pname = parent.unitName);
      this.modal = {
        show: true,
        title: "新增组织",
        submitLoading: false,
      };
    },

    getList() {
      this.table.loading = true;
      this.$post(this.$api.DEPT_MANAGE.TREE, {
        ...this.search_data,
      })
        .then((res) => {
          let str = JSON.stringify(res).replace(/childList/g, "children");
          let data = JSON.parse(str);
          this.table.data = data;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    // 启用组织
    start(row) {
      this.$post(this.$api.DEPT_MANAGE.OPEN, { unitId: row.unitId }).then(
        (res) => {
          this.$Message.success("组织启用成功");
          this.getList();
        }
      );
    },
    // 停用组织
    stop(row) {
      this.$post(this.$api.DEPT_MANAGE.STOP, { unitId: row.unitId }).then(
        (res) => {
          this.$Message.success("组织停用成功");
          this.getList();
        }
      );
    },
    // 删除组织
    delete(row) {
      this.$post(this.$api.DEPT_MANAGE.DELETE, { unitId: row.unitId }).then(
        (res) => {
          this.$Message.success("组织删除成功");
          this.getList();
        }
      );
    },

    // 编辑组织
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.modal = {
        show: true,
        title: "编辑组织",
        submitLoading: false,
      };
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        unitId: "",
        unitName: "",
        tel: "",
        pid: "",
        pname: "",
        contact: "",
        remark: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.simpleName = this.form.unitName;
        if (!params.unitCode) {
          params.unitCode = Math.floor(Math.random() * 10000).toString();
        }
        this.$post(
          params.unitId
            ? this.$api.DEPT_MANAGE.EDIT
            : this.$api.DEPT_MANAGE.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.dept-manage-page {
  width: 100%;
  height: 100%;
}
</style>